<!--
@description: coopList
@author: fc
@createtime: 2021年11月17日 12:56:34 
-->
<template>
  <div class="cooplist-scene">
    <div class="top-panel">
      <el-button type="text" @click="createNewEvent">{{
        $t("lang.createNewInvite")
      }}</el-button>
      <el-select
        v-model="sortType"
        @change="initList"
        placeholder="event type"
        class="sort-type"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="sortStatusType"
        @change="initList(sortStatusType)"
        placeholder="event type"
        class="sort-type"
      >
        <el-option label="全部" :value="0"></el-option>
        <el-option label="可申请" :value="1"></el-option>
        <el-option label="进行中" :value="2"></el-option>
        <el-option label="已完成" :value="3"></el-option>
      </el-select>
    </div>
    <div class="event-list">
      <div
        class="event-item"
        v-for="event of events"
        :key="event.id"
        @click.stop="initChangeTo(event)"
      >
        <div class="top-area">
          <div
            :class="
              event.status || event.status === 0
                ? `event-status ${statusClass[event.status]}`
                : `event-status`
            "
          >
            {{ statusNameMap[event.status] }}
          </div>
          <!-- A分享 -->
          <span
            v-clipboard:copy="`${pageLocation}?from=share&eventId=${event.id}`"
            v-clipboard:success="
              () => {
                $message.success($t('lang.shareStatus[0]'));
              }
            "
            v-clipboard:error="
              () => {
                $message.error($t('lang.shareStatus[1]'));
              }
            "
            v-if="userInfo && userInfo.id === event.postUser.id"
          >
            <i class="el-icon-share" style="font-size: 2rem"></i>
          </span>
        </div>
        <div class="event-time">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-rili-mian"></use>
          </svg>
          <el-date-picker
            size="mini"
            prefix-icon="el-icon-none"
            v-model="event.date"
            type="datetimerange"
            range-separator="—"
            :start-placeholder="$t('lang.formHolder[5]')"
            :end-placeholder="$t('lang.formHolder[6]')"
            format="yyyy-MM-dd HH:mm"
            align="right"
            class="data-pick"
            :disabled="handleShowBtn('disableDate', event)"
          >
          </el-date-picker>
        </div>
        <div class="event-targets">
          <span
            class="event-postUser"
            style="margin-right: 1rem"
            v-show="event.status == 0 && !event.receiveUser.id"
            >{{ $t("lang.invites_pub") }}</span
          >
          <span class="event-postUser">
            <img :src="event.postUser.avatar" class="postUser-avatar" />
            <span class="postUser-username"
              >{{ event.postUser.username }}
            </span>
          </span>
          <span class="event-action" v-show="event.receiveUser.id">{{
            $t("lang.invites")
          }}</span>
          <span class="event-accepter" v-show="event.receiveUser.id">
            <img :src="event.receiveUser.avatar" class="receiveUser-avatar" />
            <span class="receiveUser-username"
              >{{ event.receiveUser.username }}
            </span>
          </span>
        </div>
        <div class="event-content">{{ event.content }}</div>
        <div class="event-description">{{ event.description }}</div>
        <div class="event-bounty">
          <span class="bounty">{{ event.bounty }}</span
          ><span style="color: #b7b7c2">$coop</span>
        </div>
        <address class="event-address">
          <i class="el-icon-location"></i
          ><span style="color: #b7b7c2">{{
            event.address ? event.address : $t("lang.none")
          }}</span>
        </address>
        <div
          class="contact-container"
          v-show="handleShowBtn('contact', event) && event.status != 0"
        >
          <span class="contact-type-text">{{
            $t("lang.receiverContact")
          }}</span>
          <div class="contact-item">
            <span class="contact-type">
              <img
                class="type-icon"
                :src="typeImageCache[event.receiverContact.contactType]"
              />
              <span class="type-name">{{
                event.receiverContact.contactType
              }}</span>
            </span>
            <el-input
              disabled
              class="contact-input"
              v-model="event.receiverContact.contactDetail"
              placeholder="Include #Code"
            ></el-input>
          </div>
        </div>
        <div class="contact-container" v-show="handleShowBtn('contact', event)">
          <span class="contact-type-text">{{
            $t("lang.postUserContact")
          }}</span>
          <div class="contact-item">
            <span class="contact-type">
              <img
                class="type-icon"
                :src="typeImageCache[event.postUserContact.contactType]"
              />
              <span class="type-name">{{
                event.postUserContact.contactType
              }}</span>
            </span>
            <el-input
              disabled
              class="contact-input"
              v-model="event.postUserContact.contactDetail"
              placeholder="Include #Code"
            ></el-input>
          </div>
        </div>
        <div class="event-divide-line"></div>
        <div class="event-btns">
          <!-- 申领任务 -->
          <div class="claim-container" v-show="handleShowBtn('claim', event)">
            <el-button
              class="fc-event-btn greenBg-event-btn"
              @click.stop="collectAcceptContact(event)"
              >{{ $t("lang.claim") }}</el-button
            >
          </div>
          <!-- 取消申领任务 -->
          <div
            class="cancelClaim-container"
            v-show="handleShowBtn('cancelclaim', event)"
          >
            <span
              style="cursor: pointer"
              @click="handleCancelClaimEvent(event)"
              >{{ $t("lang.cancelclaim") }}</span
            >
          </div>
          <!-- 拒绝 -->
          <div
            class="deny-container"
            v-show="
              handleShowBtn('receiveUser', event) &&
              handleShowBtn('deny', event)
            "
          >
            <span style="cursor: pointer" @click="collectDenyReason(event)">{{
              $t("lang.notInterested")
            }}</span>
          </div>
          <!-- 接受 只有B能接受-->
          <div
            class="accept-container"
            v-show="handleShowBtn('receiveUser', event)"
          >
            <el-button
              class="fc-event-btn orangeBg-event-btn"
              v-show="handleShowBtn('accept', event)"
              @click.stop="handleClickAccept(event)"
              >{{ $t("lang.accept") }}</el-button
            >
            <el-button
              class="fc-event-btn orangeBg-event-btn"
              v-show="handleShowBtn('deny2', event)"
              @click.stop="changeToNotInterest(event)"
              >{{ $t("lang.accept")
              }}<i style="margin-left: 10px" class="el-icon-arrow-down"></i
            ></el-button>
            <el-button
              class="fc-event-btn orangeBg-event-btn"
              v-show="handleShowBtn('accept2', event)"
              @click.stop="changeToAccept(event)"
              >{{ $t("lang.notInterested")
              }}<i style="margin-left: 10px" class="el-icon-arrow-down"></i>
            </el-button>
            <div
              class="accepte-btn-container"
              v-show="
                event.isShowAcceptedSelect || event.isShowNotInterestSelect
              "
            >
              <el-button
                v-show="event.isShowAcceptedSelect"
                class="fc-event-btn orangeBg-event-btn accept-select-btn"
                @click="handleClickAccept(event)"
                >{{ $t("lang.accept") }}</el-button
              >
              <el-button
                v-show="event.isShowNotInterestSelect"
                class="fc-event-btn orangeBg-event-btn accept-select-btn"
                @click="collectDenyReason(event)"
                >{{ $t("lang.notInterested") }}</el-button
              >
            </div>
          </div>
          <!-- 取消 -->
          <div class="cancel-container" v-show="handleShowBtn('cancel', event)">
            <el-button
              class="fc-event-btn redBg-event-btn cancel-event-btn"
              @click="handleCancelEvent(event)"
              >{{ $t("lang.cancel") }}</el-button
            >
          </div>
          <!-- 编辑 -->
          <div class="edit-container" v-show="handleShowBtn('edit', event)">
            <span style="cursor: pointer" @click="handleEdit(event)">{{
              $t("lang.edit")
            }}</span>
          </div>
          <!-- 删除 -->
          <div class="edit-container" v-show="handleShowBtn('delete', event)">
            <el-button
              class="fc-event-btn redBg-event-btn confirm-event-btn"
              @click="handleDeleteEvent(event)"
              >{{ $t("lang.delete") }}</el-button
            >
          </div>
          <!-- A确认B完成 -->
          <div
            class="complete-container"
            v-show="handleShowBtn('complete', event)"
          >
            <el-button
              class="fc-event-btn greenBg-event-btn complete-event-btn"
              @click="handleCompleteEvent(event)"
              >{{ $t("lang.completed") }}</el-button
            >
          </div>
          <!-- B确认收到了bounty -->
          <div
            class="complete-container"
            v-show="handleShowBtn('received', event)"
          >
            <el-button
              class="fc-event-btn greenBg-event-btn complete-event-btn"
              @click="handleEndEvent(event)"
              >{{ $t("lang.received") }}</el-button
            >
          </div>
          <!-- 回顾，只读 -->
          <div
            class="readonly-container"
            v-show="handleShowBtn('review', event)"
          >
            <el-button
              class="fc-event-btn greyBg-event-btn confirm-event-btn"
              @click="handleReadEvent(event.id)"
              >{{ $t("lang.review") }}</el-button
            >
          </div>
        </div>
        <div class="event-comment" v-show="handleShowBtn('claimcount', event)">
          <span class="comment-count"
            >{{ $t("lang.claims") }}({{ event.applicationCount }})</span
          >
        </div>
        <div class="event-comment">
          <span class="comment-count"
            >{{ $t("lang.comments") }}({{ event.userMessageCount }})</span
          >
          <span class="comment-more"
            ><span @click="setModuleDialog([true, event.id])">{{
              $t("lang.viewmore")
            }}</span></span
          >
        </div>
      </div>
    </div>
    <el-pagination
      small
      class="event-pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="eventsTotal"
    >
    </el-pagination>
    <MFooter></MFooter>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  statusClass,
  statusNameMap_zh,
  statusNameMap_en,
} from "@data/avatar.js";
import { getAllEvents } from "@/api/public.js";
import MFooter from "../footer/footer_pc.vue";
import { getQueryParams } from "@/utils";
import btnShowMixin from "@/mixins/btnShowMixins.js";
import moduleMethodMixin from "@/mixins/moduleMethod.js";
export default {
  components: {
    MFooter,
  },
  mixins: [btnShowMixin, moduleMethodMixin],
  data() {
    return {
      pageLocation: window.location.href,
      events: [],
      options: [
        {
          value: "desc",
          label: this.$t("lang.newest"),
        },
        { value: "asc", label: this.$t("lang.oldest") },
      ],
      sortType: "desc",
      sortStatusType: 0,
      eventsTotal: 0,
      currentPage: 1,
      pageSize: 10,
      statusClass: statusClass,
      statusNameMap: statusNameMap_zh,
      fullscreenLoading: false,
    };
  },
  computed: {
    ...mapGetters(["updataListByManul"]),
  },
  watch: {
    updataListByManul() {
      this.initList();
    },
    "$i18n.locale"(val) {
      if (val == "zh-CN") {
        this.options = [
          {
            value: "desc",
            label: this.$t("lang.newest"),
          },
          { value: "asc", label: this.$t("lang.oldest") },
        ];
        this.statusNameMap = statusNameMap_zh;
      } else {
        this.options = [
          {
            value: "asc",
            label: "Oldest",
          },
          { value: "desc", label: "Newest" },
        ];
        this.statusNameMap = statusNameMap_en;
      }
    },
  },
  mounted() {
    this.initList();
  },
  methods: {
    handleClickAccept(event) {
      if (!event.receiverContact) {
        this.collectAcceptContact(event);
      } else {
        this.handleAcceptEvent();
      }
    },
    initList(sortStatusType) {
      let vm = this;
      this.setScenesloading(true);
      let params = {
        page: vm.currentPage,
        pageSize: vm.pageSize,
        createdAt: vm.sortType, //"asc" / "desc", //大小写无所谓，但是如果是具体的时间点比如2021-11-24 19:14:02那就是查这个时间点的
        // status: 1,
      };
      if (typeof sortStatusType == "number") {
        const sortMap = [[0, 1, 2, 3, 4, 5, 6], [0, 1], [2, 3, 4, 5], [6]];
        params.status = sortMap[sortStatusType];
      }
      getAllEvents(params)
        .then((res) => {
          if (res.code == 200) {
            this.events = res.data.map((item) => {
              vm.preSetEvent(item);

              return {
                id: item.id,
                status: item.status,
                date: [
                  new Date(item.startTime).getTime(),
                  new Date(item.endTime).getTime(),
                ],
                postUser: item.postUser,
                postUserContact: item.postUserContact,
                receiverContact: item.receiverContact,
                receiveUser: item.receiveUser,
                bounty: item.remuneration,
                content: item.topic,
                description: item.description,
                address: item.location,
                userMessageCount: item.userMessageCount,
                userApplied: item.userApplied,
                applicationCount: item.applicationCount,
              };
            });
            // 获取列表后判断分享弹窗，不在created/mounted中是因为获取列表会刷新dom
            const params = getQueryParams();
            if (params.from && params.from === "share") {
              if (params.eventId) {
                this.handleReadEvent(params.eventId); //只有A能编辑
                // 将链接中的search去掉，防止翻页又弹窗
                history.replaceState(
                  null,
                  "",
                  `${window.location.origin}${window.location.pathname}`
                );
              }
            }
            this.eventsTotal = this.events.length;
          } else {
            vm.$message.warning(res.message);
          }
          this.setScenesloading(false);
        })
        .catch((e) => {
          console.log(e);
          this.setScenesloading(false);
        });
    },
    createNewEvent() {
      this.setNewEventDialog(true);
    },
    handleCurrentChange() {
      this.initList();
    },
    handleSizeChange() {
      this.initList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.cooplist-scene {
  width: calc(100% - 20px);
  height: auto;
  margin-top: 72px;
  padding: 50px 10px;
  position: relative;
  background-color: $color-bg;
  .top-panel {
    background: transparent;
    padding: 0px 20px;
    width: calc(100% - 40px);
    .sort-type {
      float: right;
    }
  }
  .event-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .event-pagination {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
</style>
<style lang="scss">
@import "@/style/var.scss";
.top-panel {
  .el-button {
    background: $color-orange;
    border-radius: 20px;
    padding: 10px 10px;
    width: 190px;
    border: none;
    box-shadow: 1px 1px 2px #00000080;
    span {
      font-size: 2rem;
    }
  }
  .el-button:focus,
  .el-button:hover {
    color: $color-primary;
  }
  .el-select {
    width: 120px;
    line-height: 40px;
    .el-icon-arrow-up::before {
      content: "\e78f";
    }
  }
  .el-input__inner {
    border-radius: 20px;
  }
}
.event-list {
  .el-range-editor .el-range-input {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .el-range-separator {
    font-weight: 600;
    font-size: 1.6rem;
  }
}
.sort-type {
  .el-input__inner {
    background-color: #f1f1f1;
  }
}
.el-message-box {
  width: 360px;
}
.event-list .el-range-editor .el-range-input {
  font-size: 1.2rem;
}
</style>
